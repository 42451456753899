@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    height: 100%;
}

body {
    margin: 0;
    // font-family: poppins, "Inter";
    font-family: "Poppins", sans-serif;
}

:root {
    --primary: #1b3faa;
    --secondary: #000000;
    --font-active: #fff;
}

/* button style */
.button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-enabled {
    background-color: #1b3faa;
    /* Blue color */
    color: white;
}

.button-disabled {
    background-color: #ccc;
    /* Grey color */
    color: #666;
    /* Darker grey text */
    cursor: not-allowed;
}
input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield;
}

/* Optional: Hide spinner controls */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
::-webkit-scrollbar {
    width: 6px;
    height: 30px;
}
::-webkit-scrollbar-thumb {
    background: #1b3faa;
    border-radius: 10px;
}
.booking-data::-webkit-scrollbar {
    width: 30px;
    height: 6px;
}
.booking-data::-webkit-scrollbar-thumb {
    background: #1b3faa;
    border-radius: 10px;
}